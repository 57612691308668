<template>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <van-grid :border="false" column-num="2" class="van--grid">
      <van-grid-item v-for="(item, index) in imgList" :key="index">
        <template #default>
          <div class="photograph-list">
            <div class="img-box">
              <img :src="item.cover_url" @click="onClick(item.cover_url)" />
            </div>
          </div>
        </template>
      </van-grid-item>
    </van-grid>
  </van-list>
</template>

<script>
import { getAlbumList } from "@/api/album";
import { ImagePreview } from "vant";
export default {
  name: "photograph-list",
  props: {
    shopInfo: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      finished: false,
      img: require("@/assets//used/shop_logo.png"),
      imgList: [],
      photoStatus: {
        q_id: "",
        file_type: 1,
        page: 0,
      },
    };
  },
  methods: {
    onLoad() {
      ++this.photoStatus.page;
      this.getAlbumList("updateList");
    },
    onClick(cover) {
      // let imgArr = [];
      ImagePreview([cover]);
    },
    async getAlbumList(str) {
      this.photoStatus.q_id = this.shopInfo.id;
      // console.log(this.photoStatus);
      const res = await getAlbumList(this.photoStatus);
      // console.log("----getAlbumList-------", res);
      if (str === "updateList") {
        this.imgList = [...this.imgList, ...res.data.items];
      } else {
        this.imgList = res.data.items;
      }
      this.loading = false;
      if (this.imgList.length >= res.data.total) {
        this.finished = true;
        this.photoStatus.page = 1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-grid-item__content {
  padding: 0;
  background-color: transparent;
}
.van--grid {
  display: flex;
  justify-content: space-between;
  padding: 0px 12px;
}
.photograph-list {
  padding-top: 12px;
}
.img-box {
  width: 188px;
  height: 188px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
</style>