<template>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <van-grid :column-num="2" :border="false" class="van--grid">
      <van-grid-item
        v-for="goods in goodsList"
        :key="goods.id"
        :to="`/used-details?goodsId=${goods.id}`"
      >
        <template #default>
          <div class="grid-box">
            <div class="goods-img">
              <img :src="goods.used_img" />
            </div>
            <div class="goods-info">
              <p class="title">
                {{ goods.used_name }}
              </p>
              <div class="price">
                <div class="present-price">
                  <span>￥</span>
                  <span class="integer">{{ toInteger(goods.price) }}</span>
                  <span>{{ twoFloating(goods.price) }}</span>
                </div>
                <div class="original-price">
                  <span>￥</span>
                  <span>{{ toInteger(goods.original_price) }}</span>
                  <span>{{ twoFloating(goods.original_price) }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </van-grid-item>
    </van-grid>
  </van-list>
</template>

<script>
import { getUsedGoodsList } from "@/api/used";
import tools from "@/common/js/tools";
export default {
  name: "goods-list",
  props: {
    type: {
      type: Number,
      default: 1,
    },
    goodsFind: {
      type: Object,
    },
  },
  data() {
    return {
      routerVal: "",
      finished: false,
      loading: false,
      goodsList: [],
    };
  },
  watch: {
    goodsFind: {
      deep: true,
      handler: function (newV, oldV) {
        this.getGoodsList("search");
      },
    },
  },
  methods: {
    onLoad() {
      this.getGoodsList();
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // if (typeof num === "number") {
      //   price = String(num).split(".")[1];
      //   if (price !== undefined && price.length === 1) {
      //     price = `.${price}0`;
      //   } else {
      //     price === undefined ? (price = ".00") : (price = `.${price}`);
      //   }
      // }
      // return price;
    },
    async getGoodsList(search) {
      console.log("----请求之前触发----", this.goodsFind);
      const res = await getUsedGoodsList(this.goodsFind);
      console.log(res);
      if (search === "search") {
        this.goodsList = res.data.items;
        return;
      }
      this.goodsList = [...this.goodsList, ...res.data.items];
      this.loading = false;
      if (this.goodsList.length >= res.data.total) {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.van-grid-item {
  width: 189px !important;
  flex-basis: 0 !important;
}
/deep/.van-grid-item__content {
  padding: 0;
  background-color: transparent;
}
.van--grid {
  display: flex;
  justify-content: space-between;
  padding: 0px 12px;
}
.grid-box {
  background-color: #fff;
  width: 189px;
  height: 100%;
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
  .goods-img {
    width: 100%;
    height: 189px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .goods-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    height: 69px;
    p {
      font-size: 16px;
      margin: 0;
      display: -webkit-box; /**对象作为伸缩盒子模型展示**/
      -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
      -webkit-line-clamp: 2; /**显示的行数**/
      overflow: hidden; /**隐藏超出的内容**/
      word-break: break-all;
    }
    .price {
      display: flex;
      align-items: flex-end;
      margin-top: 6px;
      font-size: 14px;
      .present-price {
        margin-right: 10px;
        color: #ed301d;
        .integer {
          font-weight: 500;
          font-size: 18px;
        }
      }
      .original-price {
        text-decoration: line-through;
        color: #d5d5d5;
      }
    }
  }
}
</style>
