<template>
  <div>
    <NavBar class="nav-bar">
      <div slot="title">卖家信息</div>
    </NavBar>
    <div class="detail-content">
      <div class="shop-detail">
        <div class="shop-img">
          <img :src="shopInfo.head_img || defaultImg" alt=""/>
        </div>
        <div class="shop-info">
          <div class="shop-title">{{ shopInfo.nickname }}</div>
        </div>
      </div>
      <div class="shop-introduce" v-if="shopInfo.describe!==''">
        <div class="introduce-title">介绍</div>
        <div class="introduce-content">
          {{ shopInfo.describe }}
        </div>
      </div>
      <van-tabs v-model="active" title-inactive-color="#AAAAAA">
        <van-tab
          title="闲置商品"
          :title-class="[{ 'title-weight': active === 0 }, 'title-text']"
        >
          <GoodsList :goodsFind="goodsFind"></GoodsList>
        </van-tab>
        <van-tab
          title="照片"
          :title-class="[{ 'title-weight': active === 1 }, 'title-text']"
        >
          <PhotographList :shopInfo="shopInfo"></PhotographList>
        </van-tab>
        <van-tab
          title="视频"
          :title-class="[{ 'title-weight': active === 2 }, 'title-text']"
        >
          <VideoList :shopInfo="shopInfo"></VideoList>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import GoodsList from "../components/goods-list/goods-list";
import PhotographList from "../components/photograph-list/photograph-list";
import VideoList from "../components/video-list/video-list";
import {getShopInfo} from "@/api/used";

export default {
  name: "seller-info",
  components: {
    NavBar,
    GoodsList,
    PhotographList,
    VideoList,
  },
  data() {
    return {
      defaultImg: require("@/assets/used/shop_logo.png"),
      active: 0,
      shopId: this.$route.query.shopId,
      shopInfo: {
        head_img: "",
        nickname: "",
        describe: "",
        id: "",
      },
      goodsFind: {

        order_by: 1,
        used_name: "",
        sell_id: this.$route.query.shopId || '',
        area_id: "",
        province_id: "",
        city_id: "",
      },
    };
  },
  async mounted() {
    //获取店铺基本信息
    const res = await getShopInfo({shop_id: this.shopId});
    console.log(res);
    if (res.code * 1 === 1) {
      this.shopInfo = res.data;
      this.goodsFind.sell_id = res.data.id;
    }
  },
  methods: {},
};
</script>

<style lang="less" scoped>
@inforowheight: 52px; //商铺信息高度
.nav-bar {
  border-bottom: 1px solid rgb(241, 241, 241);
  background-color: #fff;
}

.detail-content {
  min-height: 100vh;
  margin-top: 52px;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #ffffff, #ffffff, #f2f2f2);
}

.shop-detail {
  display: flex;
  align-items: center;
  padding: 26px 12px;

  .shop-img {
    width: @inforowheight;
    height: @inforowheight;
    border-radius: 8px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .shop-info {
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    height: @inforowheight;

    .shop-title {
      font-size: 17px;
      font-weight: 500;
    }
  }
}

.shop-introduce {
  border-bottom: 1px solid #e4e4e4;
  margin: 0 12px;
  padding-bottom: 14px;

  .introduce-title {
    font-size: 21px;
    font-weight: 549;
    margin-bottom: 4px;
  }

  .introduce-content {
    font-size: 17px;
    word-break: break-all;
  }
}

/deep/ .title-text {
  font-size: 17px;
}

/deep/ .title-weight {
  font-weight: 500;
}

/deep/ .van-tabs__nav {
  justify-content: start;
  padding-left: 12px;
  padding-right: 12px;
}

/deep/ .van-tab {
  flex: none;
  margin-right: 49px;
}

/deep/ .van-tabs__wrap {
  margin-top: 10px;
}

/deep/ .van-tab__pane {
  margin-top: 16px;
}
</style>
