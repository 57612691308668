<template>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <div class="content">
      <div class="video-container" v-for="(item, index) in list" :key="item.id">
        <div @click="showVideo(index)">
          <!--显示层-->
          <img :src="item.cover_url" alt="" />
          <div class="video-play">
            <van-icon name="play" size="18" color="#FFFFFF" />
          </div>
        </div>
        <van-popup
          v-model="item.isPlayer"
          :closeable="true"
          :close-on-click-overlay="false"
          @click-close-icon="closeVideo"
        >
          <!--播放层-->
          <Player ref="videoServe" :url="item.file_url"></Player>
        </van-popup>
      </div>
    </div>
  </van-list>
</template>

<script>
import { getAlbumList } from "@/api/album";
import Player from "@/components/video/video";
export default {
  name: "video-list",
  components: {
    Player,
  },
  props: {
    shopInfo: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      finished: false,
      playVideo: false,
      list: [],
      isAjax: false,
      checked: false,
      isSelect: "none",
      imgList: [],
      page: 1,
      total: 100,
      videoIndex: 0,
    };
  },
  methods: {
    onLoad() {
      this.getAlbumList();
    },
    closeVideo() {
      this.list[this.videoIndex].isPlayer = 0;
      if (this.$refs.videoServe[this.videoIndex]) {
        this.$refs.videoServe[this.videoIndex].stopVideo();
      }
    },
    showVideo(index) {
      this.videoIndex = index;
      this.list.forEach((value, key) => {
        if (key !== index) {
          value.isPlayer = false;
        }
      });
      this.list[index].isPlayer = true;
    },
    async getAlbumList() {
      //获取订单列表数据
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      this.finished = false;
      const ret = await getAlbumList({
        file_type: 2,
        page: this.page,
        q_id: this.shopInfo.id,
      });
      console.log("--------视频列表---------", ret);
      if (ret.code * 1 == 1) {
        this.page++;
        let list_num = ret.data.items.length;
        if (list_num >= 0) {
          for (let i = 0; i < list_num; i++) {
            ret.data.items[i].isSelect = 0;
            ret.data.items[i].isPlayer = false;
          }
        }
        this.list = [...this.list, ...ret.data.items];
        this.total = ret.data.total;
      }
      this.loading = false;
      if (this.list.length >= this.total) {
        this.finished = true;
      }
      this.isAjax = false;
    },
  },
};
</script>

<style lang="less" scoped>
.video-container {
  position: relative;
  height: 206px;
  padding: 0 12px;
  margin-top: 12px;
  img {
    width: 100%;
    height: 206px;
  }
  .video-play {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    height: 48px;
    background: rgba(0, 0, 0, 0.22);
    border: 1px solid #ffffff;
    opacity: 1;
    border-radius: 50%;
  }
}

.van-popup {
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0);
  transform: translate3d(0, -50%, 0);
}
</style>